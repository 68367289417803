import classNames from "classnames"
import React, { ReactElement, useRef } from "react"
import styled from "styled-components"
import cls from "classnames"
import { navigate } from "gatsby"
export interface BrandProps {
  image: string
  title: string
  logo: string
  descriptionTextColor: string
  description: string
  index: number
  link: string
  count: number
  positionLeft: boolean
}

function Brand({
  image,
  title,
  logo,
  descriptionTextColor,
  positionLeft,
  description,
  index,
  count,
  link,
}: BrandProps): ReactElement {
  const getClipPath = () => {
    if (index === 0) {
      if (count === 1) {
        return "polygon(0 0, 100% 0, 100% 100%, 0 100%)"
      } else {
        return "polygon(0 0, 100% 0, 100% 80%, 0 100%)"
      }
    } else if (index % 2 == 1) {
      if (count == index + 1) {
        return "polygon(0 20%, 100% 0, 100% 100%, 0 100%)"
      } else {
        return "polygon(0 20%, 100% 0, 100% 100%, 0 80%)"
      }
    } else {
      if (count == index + 1) {
        return "polygon(0 0, 100% 20%, 100% 100%, 0 100%)"
      } else {
        return "polygon(0 0, 100% 20%, 100% 80%, 0 100%)"
      }
    }
  }
  interface MarginStyle {
    marginTop?: number
    clipPath: string
    backgroundImage: string
  }
  const getStyle = () => {
    const style = {} as MarginStyle
    style.clipPath = getClipPath()
    style.backgroundImage = `url(${image})`
    if (index === 0) style.marginTop = 0
    return style
  }
  return (
    <div className={`bg-brand-500  z-${50 - index * 10}`}>
      <div
        className={`relative brand-item bg-brand-500 bg-no-repeat bg-center bg-cover bg-fixed h-screen flex flex-col justify-center  ${
          positionLeft ? "items-start" : "items-end"
        } `}
        style={getStyle()}
      >
        <div
          className={`flex flex-col w-full md:w-5/12 p-4 ${
            positionLeft ? "items-start" : "items-end"
          }    md:items-center`}
        >
          <h1 className="text-3xl md:text-5xl font-extrabold text-brand-500 pb-5">
            {title}
          </h1>
          <p
            className={`text-lg font-semibold ${
              positionLeft ? "text-left" : "text-right"
            } md:text-center text-white-lighter pt-4`}
            style={{
              color: descriptionTextColor,
            }}
          >
            {description}
          </p>
          <img
            className="translate-y-0 cursor-pointer  hover:shadow-brand  transform  transition-all ease duration-300 hover:-translate-y-2  mt-10 w-28 h-28 z-10 rounded-full border-brand-600 border-4 shadow-sm"
            src={`${logo}`}
            onClick={e => {
              navigate(link)
            }}
            alt={`Logo - ${title}`}
          />
        </div>
      </div>
    </div>
  )
}

export default Brand

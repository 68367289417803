import React, { ReactElement } from "react"
import Section from "../../components/common/Section"
import Brand from "./Brand"
import { GatsbyGraphQLType, graphql, useStaticQuery } from "gatsby"

interface BrandsProps {
  id?: string
}
interface BrandsData {
  title: string
  logo: string
  description: string
  link: string
  date: string
  descriptionTextColor: string
  image: string
}

interface BrandsGraphQlData {
  allMarkdownRemark: {
    edges: [
      {
        node: {
          id: string
          frontmatter: BrandsData
        }
      }
    ]
  }
}

function BrandsSection({ id }: BrandsProps): ReactElement {
  const query = graphql`
    {
      allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/brands/" } }) {
        edges {
          node {
            id
            frontmatter {
              image
              descriptionTextColor
              link
              logo
              title
              description
            }
          }
        }
      }
    }
  `
  const data = useStaticQuery<BrandsGraphQlData>(query)
  const brands = data.allMarkdownRemark.edges.map(edge => edge.node)
  return (
    <Section id={id}>
      <div className="flex flex-col items-center mt-20 mb-20">
        <h1 className="text-5xl xs:text-6xl font-bold">Our Brands</h1>
        <p className="my-8 text-xl">
          interested in one of our services or have input for one of our shows?
        </p>
      </div>
      {brands.map((item, index) => (
        <Brand
          {...item.frontmatter}
          index={index}
          key={item.id}
          count={brands.length}
          positionLeft={index % 2 === 1}
        ></Brand>
      ))}
      <style scoped>{`
                      .brand-item{
                        margin-top: -40%;
                      }
                       
                        @media (min-width: 640px) {
                          .brand-item{
                            margin-top: -30%;
                          }
                        }
                        @media (min-width: 768px) {
                          .brand-item{
                            margin-top: -20%;
                          }
                          
                        }
                        @media (min-width: 1024px) {
                          .brand-item{
                            margin-top: -8%;
                          } 
                        }
                    `}</style>
    </Section>
  )
}

export default BrandsSection

import React from "react"
import GetInTouch from "../sections/GetInTouch"
import HeroSection from "../sections/HeroSection"
import SocialShort from "../sections/SocialShortSection"
import Layout from "../components/layout/Layout"
import NewsSection from "../sections/NewsSection"
import BrandsSection from "../sections/OurBrands"
export default () => {
  return (
    <Layout>
      <HeroSection id="hero"></HeroSection>
      <BrandsSection id="brands"></BrandsSection>
      <SocialShort id="social"></SocialShort>
      <NewsSection id="news"></NewsSection>
      <GetInTouch id="getintouch"></GetInTouch>
    </Layout>
  )
}
